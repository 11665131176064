@import "common/variables";
@import "common/breakpoints";
@import "common/global";

@import "components/header";
@import "components/footer";
@import "components/partners";
@import "components/datepicker";
@import "components/page_header";
@import "components/page_highlighter";
@import "components/page_number";
@import "components/input";
@import "components/gosafe";
@import "components/button";
@import "components/form";

@import "layouts/frontpage";

@import "fonts/open_sans";
@import "fonts/heebo";

* {
  box-sizing: border-box;
}

.App {
  background-color: var(--color--white);
  color: var(--color--gray-dark);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.input-element {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}