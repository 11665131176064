footer {
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 3rem 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background-color: var(--color--black);
  color: var(--color--white);

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  @include media-breakpoint-down(md) {
    padding: 3rem;
  }

  a {
    color: var(--color--white);

    &:hover {
      color: var(--color--primary);
    }
  }

  .footer-info,
  .footer-help {
    width: 100%;
    max-width: 300px;
    text-align: left;
  }

  .footer-info {
    p {
      margin-top: 0;
    }

    p:not(:last-child) {
      margin-bottom: 0;
    }
  }
  
  .footer-levelup {
    order: 3;

    @include media-breakpoint-up(lg) {
      order: 2;
    }
  }

  .footer-help {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    order: 2;
    
    @include media-breakpoint-up(lg) {
      order: 3;
      justify-content: flex-end;
    }

    a.help-link {
      padding: 0.6rem;
      height: 2.4rem;
      width: 2.4rem;
      text-align: center;
      border-radius: 2rem;
      background-color: var(--color--primary);
      color: var(--color--white);
      text-decoration: none;
      display: inline-block;
    }
  }

  .footer-levelup {
    .site-by-levelup {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      justify-content: center;
    
      a {
        text-decoration: none;
      }
    }
  }
}
