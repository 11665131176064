body {
  --color--primary: #E10098;
  --color--primary-hover: #FF1EB6;
  --color--secondary: #5F4B8B;
  --color--turquoise: #00A9CE;
  --color--secondary-dark: #6a6577;
  --color--secondary-light: #C1B7D8;
  --color--dark-blue: #001529;
  --color--gray-dark: #262626;
  --color--gray: #D9D9D9;
  --color--white: #FFF;
  --color--black: #000;

  --content--width--default: 1180px;
  --content--width--narrow: 1040px;
  --content--width--wide: 1240px;

  --transition--global: 150ms ease;

  --input--border-radius: 3px;
  --input--padding: 0.75rem;

  --button--border-radius: 30px;

  --font-family--primary: 'Open Sans', sans-serif;
  --font-family--heading: 'Heebo', sans-serif;
}
