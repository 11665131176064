@import "../../assets/styles/common/breakpoints";

.field-wrapper {
  padding: 0.75rem 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  gap: 0.5rem;
  
  @include media-breakpoint-up(md) {
    gap: 1rem;
    flex-direction: row;
  }
  
  label {
    @include media-breakpoint-up(md) {
      margin-top: var(--input--padding);
      text-align: right;
    }
  }
}

.input-wrapper {
  width: 100%;
  max-width: 400px;
}