html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-family--primary);
  color: black;
}

a {
  color: black;
  text-decoration: underline;
  transition: var(--transition--global);

  &:hover {
    text-decoration: underline;
    color: var(--color--gray);
  }
}

p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mt-auto {
  margin-top: auto;
}

.text-center {
  text-align: center;
}

.bg-white {
  background-color: white;
  padding: 2rem;
}

.layout-horizontal {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.layout-vertical {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
}

.form-content__wrapper {
  padding: 2rem;
}

.content-container {
  max-width: 700px;
  margin-inline: auto;
  
  @include media-breakpoint-up(md) {
    padding-inline: 2rem;
  }
}

.large-text {
  font-size: 1.6rem;
  font-weight: 900;
}

.color-primary {
  color: var(--color--primary);
}

.color-red {
  color: red;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-family: var(--font-family--heading);
}

.heading {
  text-transform: uppercase;
}