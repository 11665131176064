.frontpage {
  text-align: center;
  width: 100%;

  .frontpage-bg {
    background-image: url(../../images/suomenautorahasto-rahoitushakemus.jpg); //linear-gradient(152deg,rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 99%);
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    color: var(--color--white);
    margin-left: -2%;
    margin-right: -2%;
    transform: rotate(1.5deg);
    -ms-transform: rotate(1.5deg);
    -webkit-transform: rotate(1.5deg);

    .frontpage-banner {
      background: rgba(0,0,0,0.5);
      padding: 6rem 2rem;

      @include media-breakpoint-down(md) {
        padding: 3rem 1rem;

        h1 {
          margin-bottom: 0;
        }

        p {
          display: none;
        }
      }

      h1 {
        text-transform: uppercase;
        color: var(--color--white);
        font-size: 2.5rem;
        padding: 0 1rem;
        transform: rotate(-1.5deg);
        -ms-transform: rotate(-1.5deg);
        -webkit-transform: rotate(-1.5deg);
      }

      p {
        font-weight: 700;
        font-size: 1.25rem;
        padding: 0 1rem;
        transform: rotate(-1.5deg);
        -ms-transform: rotate(-1.5deg);
        -webkit-transform: rotate(-1.5deg);
      }
    }
  }

  .frontpage-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    width: 100%;
    max-width: var(--content--width--narrow);
    margin: auto;
    padding: 4rem 3rem;

    @include media-breakpoint-down(md) {
      padding: 2rem;
      gap: 1rem;
    }
  }

  .mobile-text {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}