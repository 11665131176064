.input-field {
  border: 2px var(--color--gray) solid;
  border-radius: 10px;
  padding: var(--input--padding);
  width: 100%;
  font-size: 16px;

  @include media-breakpoint-up(lg) {
    width: 400px;
  }
}