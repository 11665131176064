.page-logo {
  background-color: var(--color--dark-blue);
  padding: 0.3rem 1rem;
  width: 100%;

  img {
    display: block;
    margin-inline: auto;
    width: 100%;
    max-width: 100px;
  }
}

.page-header {
  width: 101%;
  text-align: center;
  background-color: var(--color--primary);
  color: var(--color--white);
  margin-bottom: 3rem;
  padding: 2rem 2rem 1rem;
  margin-left: -5%;
  margin-right: -5%;
  transform: rotate(1.5deg);
  -ms-transform: rotate(1.5deg);
  -webkit-transform: rotate(1.5deg);

  .page-header__title {
    font-size: 1.75rem;
    transform: rotate(-1.5deg);
    -ms-transform: rotate(-1.5deg);
    -webkit-transform: rotate-(1.5deg);
  }
}