.gosafe__wrapper {
  width: 100%;
  max-width: var(--content--width--default);
  margin-inline: auto;
}

.gosafe-item__wrapper {
  background-color: var(--color--turquoise);
  color: white;
  padding: clamp(1rem, 2vw, 2rem);
  text-align: center;

  img {
    background-color: white;
    margin-bottom: 1rem;
  }

  a {
    color: white;
  }

  .gosafe__price {
    font-size: clamp(1.6rem, 2.4vw, 2.2rem);
    font-weight: bold;
    border-bottom: 4px var(--color--primary) solid;
  }

  .gosafe__readmore {
    display: inline-block;
    margin-top: 1rem;
  }
}