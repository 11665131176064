.page-highlighter {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  position: relative;
  --bar-height: 2px;

  @include media-breakpoint-up(xl) {
    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 4px;
      background-color: var(--color--secondary);
      left: 0;
      top: 50%;
      z-index: 0;
    }
  }

  @include media-breakpoint-down(xl) {
    padding: 0 2rem;
  }
}
