.button {
  padding: 0.8rem 1.6rem;
  border-radius: var(--button--border-radius);
  background-color: var(--color--primary);
  border: none;
  color: white;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  transition: var(--transition--global);
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;

  &:hover {
    background-color: var(--color--secondary);
    color: white;
    text-decoration: none;
  }

  &:disabled {
    background-color: var(--color--secondary-light);
    cursor: not-allowed;
  }
  
  .loading-icon__wrapper {
    margin-left: 0.5rem;

    img {
      max-height: 20px;
    }
  }
}