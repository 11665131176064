.fieldset {
  border: none;
  padding: 0;

  legend {
    margin-bottom: 1rem;
  }
}

.radio-options__wrapper {
  display: flex;
  gap: 1rem;

  input:checked ~ .checkmark:after {
    display: block;
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border: 1px var(--color--turquoise) solid;
  border-radius: 50%;

  &::after {
    content: '';
    top: 2px;
    left: 2px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: var(--color--turquoise);
    position: absolute;
    display: none;
  }
}

.label {
  margin: 0.5rem 0;
  position: relative;
  cursor: pointer;
}

.label-text {
  padding-left: 0.5rem;
}
