.page-number__wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--color--white);
  position: relative;
  z-index: 1;
  padding: 0 0.5rem;
  width: 100%;
  font-size: 0.9rem;
  font-weight: 700;

  @include media-breakpoint-up(md) {
    width: auto;
  }

  .page-number {
    color: var(--color--white);
    font-size: 1rem;
    
    > span {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--color--secondary);
      border: 1px var(--color--secondary) solid;
      
      @include media-breakpoint-up(xl) {
        width: 40px;
        height: 40px;
      }
    }

    &.active {
      > span {
        background-color: white;
        color: var(--color--secondary);
      }
    }
  }
}

